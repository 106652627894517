/* title */

*{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
}
 body {
     background-color: #FFFFFF;
     margin: 0;
     padding: 0;
 }

 body .font-family-caveat {
    font-family: 'Caveat';
  }

*, :after, :before {
    box-sizing: border-box;
}
.h-100 {
    height: 100% !important;
}
.lowercase {
    text-transform: lowercase;
}
.menuHeader {
    padding: 8px 0px 0px 16px;
}
.bosMenuLogo {
    height: 32px;
}
.border {
    border: 1px solid lightgray;
}

.pointer:hover {
    cursor: pointer !important;
}
.pointer-blue:hover {
    cursor: pointer !important;
    color: #007BFF;
}
.grab {
    cursor: move;
}

.font-weight-bold {
    font-weight: 500;
}

.lightGrey {
    background-color: #F2F2F2 !important;
}

.mt-1 { margin-top: 4px !important; }
.mt-2 { margin-top: 8px !important; }
.mt-3 { margin-top: 12px !important; }
.mr-1 { margin-right: 4px !important; }
.mr-2 { margin-right: 8px !important; }
.mr-3 { margin-right: 12px !important; }
.mr-4 { margin-right: 16px !important; }
.mr-5 { margin-right: 20px !important; }
.mr-6 { margin-right: 24px !important; }
.ml-1 { margin-left: 4px !important; }
.ml-2 { margin-left: 8px !important; }
.pt-6 { padding-top: 40px !important; }
.p-4 { padding: 16px !important; }

.userMenu-p {
    padding: 6px 16px !important;
}
.userMenu-p-title {
    padding: 2px 16px !important;
}

.heading {
    font-size: 16px;
    color:#000;
}
/* Icon Size */
.md-8 { font-size: 8px !important; }
.md-10 { font-size: 10px !important; }
.md-12 { font-size: 12px !important; }
.md-14 { font-size: 14px !important; }
.md-16 { font-size: 16px !important; }
.md-18 { font-size: 18px !important; }
.md-20 { font-size: 20px !important; }
.md-24 { font-size: 24px !important; }
.md-36 { font-size: 36px !important; }
.md-48 { font-size: 48px !important; }

/* Width */
.w-100 { min-width: 100% !important; }

/* Spacing  */
.spacing-1 { padding: 8px !important; }
.spacing-2 { padding: 16px !important; }
.spacing-3 { padding: 24px !important; }

/* Margin and padding */
.ml-0 { margin-left: 0px !important; }
.mt-0 { margin-top: 0px !important }
.pt-0 { padding-top: 0px !important }

.mt-20 { margin-top: 20% !important}
.mt-2-per { margin-top: 2% !important}

.font-weight-bold { font-weight: 500 !important; }
:hover.icon-hover{ color: #007BFF; }
.rt-th { background-color: #FFF !important; }

.bg-light-yellow { background-color: #FFFDC1; }
.handle { background-color:  #FF9CA1; }
.ongoing { background-color:  #FFFFFF;}
.expired { background-color:   #E5E5E5;}

.modal-z-index { z-index: 9999; }

.zindex1001 { z-index: 1001; }

.uppercase { text-transform: uppercase; }
.text-danger {
    background-color: #FFFFFF;
    color: #f44336 !important;
}

.d-flex {
  display: flex;
}

.white {
    background-color: #FFFFFF;
}

.space-between {
  justify-content: space-between;
}

.v-align-middle {
  vertical-align: middle;
}

.profile-icon {
    height: 169px !important;
    width: 169px !important;
    padding: 0px !important;
}

.text-link:hover {
    color: blue !important;
}

.news-popover {
    padding: 8px !important;
    width: 320px !important;
}

.w-50 {
    width: 50px !important;
}

.comment-popover-width {
    width: 350px !important;
}

.text-strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline !important;
}

.op-2 { opacity: 0.2; }
.op-4 { opacity: 0.4; }

.middle {
  vertical-align: middle;
}
#map * {
    overflow:visible;
}