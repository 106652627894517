.navbarAutoComplete {
  background: #ede7e7;
  border-radius: 6px;
}

.navbarMaterialInputTextFieldClass {
  height: 25px !important;
  width: 250px !important;
}

.autoCompleteSuggestionsOption {
  background-color: white !important;

  &[data-focus="true"] {
    background-color: #ede7e7 !important;
  }
}