@import './colors.module.scss';

.Appbar {
  background-color: $white !important;
  border-bottom: 1px solid $default !important;
}

.MenuText {
  align-self: center !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-family: Inter !important;
}

.Paper {
  height: 48px !important;
  width: 208px !important;
  margin-right: 20px !important;
  // background-color: $white !important;
  display: flex !important;
  // justify-content: center !important;
  margin-right: 24px !important;
  cursor: pointer !important;
}

.Paper:hover {
  background-color: $primary2Hover !important;
}

.alertBarPadding {
  padding-top: 88px;
}

.noAlertBarNavbarPadding {
  padding-top: 49px;
}

.companyIcon {
  width: 38px;
  height: 36px;
  background-color: #250B47;
  color: $white;
  font-family: Inter !important;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  align-items: center;
  margin-right: 16px;
}
